import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Layout>
      <Seo title="404: Not found" />
      <h1>404: Seite nicht gefunden</h1>
      <p>Bitte kehren Sie zur Startseite zurück</p>
    </Layout>
  </>
)

export default NotFoundPage
